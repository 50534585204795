const Choices = require("choices.js");
document.addEventListener("turbo:load", function() {
  if (!document.querySelector('.admin')) {
    const multipleSelectElements = Array.prototype.slice.apply(
      document.querySelectorAll("select[multiple], .multiselect")
    );

    const autoSelectElements = Array.prototype.slice.apply(
      document.querySelectorAll(".autoselect")
    );

    multipleSelectElements.forEach(element => {
      const maxItemCount = (element.dataset && element.dataset["max"]) || -1;
      const maxItemText =
        maxItemCount == 1
          ? `Only a single choice can be selected`
          : `Only ${maxItemCount} choices can be selected`;
      const itemTabIndex = (element.dataset && element.dataset["tabindex"]) || 5;
      new Choices(element, {
        removeItemButton: true,
        noResultsText: "No results found",
        noChoicesText: "No more choices",
        maxItemText: maxItemText,
        maxItemCount: maxItemCount,
        searchFields: ["label"],
        addItems: true,
        duplicateItemsAllowed: false,
        callbackOnInit: function() {
          if (this && this.input && this.input.element) {
            this.input.element.tabIndex = itemTabIndex;
          }
        },
        // callbackOnInit: function() {
        //   // // if (!window.choices) {
        //   // //   window.choices = this;
        //   // // }
        //   // // console.log(this.passedElement.element);
        //   // // .passedElement.triggerEvent(EVENTS.addItem
        //   // window.choices = this;
        //   // this.passedElement.element.addEventListener("search", function(e) {
        //   //   console.log("typing");
        //   //   // console.log(choices._store.activeChoices.length > 0);
        //   //   // console.log(e.detail.value);
        //   //   // if (choices._store.activeChoices.length == 0) {
        //   //   clonedInput.value = e.detail.value;
        //   //   // }
        //   // });
        //   this.passedElement.element.addEventListener("blur", function(e) {
        //     console.log("blur", e.detail.value);
        //   });
        //   this.passedElement.element.addEventListener("change", function(e) {
        //     console.log("chagne", e.detail.value);
        //   });
        // },
        classNames: {
          containerOuter: "multiselect",
          containerInner: "multiselect-body",
          input: "multiselect-input",
          inputCloned: "multiselect-input-cloned",
          list: "multiselect-list",
          listItems: "multiselect-list-items",
          listSingle: "multiselect-list-single",
          listDropdown: "multiselect-list-dropdown",
          item: "multiselect-item",
          itemSelectable: "multiselect-selectable",
          itemDisabled: "multiselect-disabled",
          itemChoice: "multiselect-choice",
          placeholder: "multiselect-placeholder",
          group: "multiselect-group",
          groupHeading: "multiselect-heading",
          button: "multiselect-button",
          activeState: "is-active",
          focusState: "is-focused",
          openState: "is-open",
          disabledState: "is-disabled",
          highlightedState: "is-highlighted",
          selectedState: "is-selected",
          flippedState: "is-flipped",
          loadingState: "is-loading",
          noResults: "has-no-results",
          noChoices: "has-no-choices"
        }
      });
    });

    autoSelectElements.forEach(element => {
      const itemTabIndex = (element.dataset && element.dataset["tabindex"]) || 1;
      const choicesList = (element.dataset && element.dataset["choices"]) || {};

      new Choices(element, {
        removeItemButton: false,
        noResultsText: "No results found",
        choices: JSON.parse(choicesList),
        searchEnabled: true,
        searchChoices: true,
        searchResultLimit: 5,
        searchPlaceholderValue: 'Select one',
        searchFields: ["label", "value", "customProperties.synonyms.value"],
        fuseOptions: {
          minMatchCharLength: 2,
          threshold: 0.2,
          ignoreLocation: true,
          ignoreFieldNorm: true
        },
        callbackOnInit: function() {
          if (this && this.containerOuter && this.containerOuter.element) {
            this.containerOuter.element.tabIndex = itemTabIndex;
          }
        },
        classNames: {
          containerOuter: "multiselect multiselect-single",
          containerInner: "multiselect-body",
          input: "multiselect-input",
          inputCloned: "multiselect-input-cloned",
          list: "multiselect-list",
          listItems: "multiselect-list-items",
          listSingle: "multiselect-list-single",
          listDropdown: "multiselect-list-dropdown",
          item: "multiselect-item",
          itemSelectable: "multiselect-selectable",
          itemDisabled: "multiselect-disabled",
          itemChoice: "multiselect-choice",
          placeholder: "multiselect-placeholder",
          group: "multiselect-group",
          groupHeading: "multiselect-heading",
          button: "multiselect-button",
          activeState: "is-active",
          focusState: "is-focused",
          openState: "is-open",
          disabledState: "is-disabled",
          highlightedState: "is-highlighted",
          selectedState: "is-selected",
          flippedState: "is-flipped",
          loadingState: "is-loading",
          noResults: "has-no-results",
          noChoices: "has-no-choices"
        }
      });
    });
  }
});
