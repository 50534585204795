import { Controller } from "stimulus"

const loadingClass = 'loading';

export default class extends Controller {
  static targets = [ "button" ];

  initialize() {}

  createInterview(event) {
    var buttonElements = this.buttonTarget.querySelectorAll('button, .button');

    Array.from(buttonElements).forEach(function(buttonElement) {
      buttonElement.classList.add(loadingClass)
      buttonElement.disabled = 'disabled';

      setTimeout(function(){
        buttonElement.classList.remove(loadingClass)
        buttonElement.removeAttribute('disabled');
      }, 3000)
    });
  }

  previewSource(event) {
    // var buttonElements = this.buttonTarget.querySelectorAll('button, .button');
    //
    // Array.from(buttonElements).forEach(function(buttonElement) {
    //   buttonElement.classList.add(loadingClass)
    //   buttonElement.disabled = 'disabled';
    //
    //   setTimeout(function(){
    //     buttonElement.classList.remove(loadingClass)
    //     buttonElement.removeAttribute('disabled');
    //   }, 3000)
    // });
  }
}
