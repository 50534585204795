import noUiSlider from "nouislider";
import wNumb from "./numberFormatter.js";

document.addEventListener("turbo:load", function() {
  var rangeSlider = document.querySelector(".js-range-slider");
  if (rangeSlider) {
    var minInput = rangeSlider.querySelector("[name*='min']");
    var minValue = minInput && parseInt(minInput.dataset["min"]);
    var maxInput = rangeSlider.querySelector("[name*='max']");
    var maxValue = maxInput && parseInt(maxInput.dataset["max"]);
    var rangeInputs = [minInput, maxInput];

    noUiSlider.create(rangeSlider, {
      start: [minInput.value, maxInput.value],
      connect: true,
      step: 1,
      margin: 3,
      padding: [minValue, 0],
      tooltips: [
        true,
        wNumb({ decimals: 0, suffix: maxInput.value == maxValue ? "+" : "" })
      ],
      format: {
        to: function(value) {
          return Math.round(value);
        },
        from: function(value) {
          return Math.round(value);
        }
      },
      cssPrefix: "range-slider-",
      range: {
        min: 0,
        max: maxValue
      }
    });
    rangeSlider.noUiSlider.on("update", function(values, handle) {
      let value = values[handle];
      rangeInputs[handle].value = value;
    });
    rangeSlider.noUiSlider.on("slide", function(values, handle) {
      if (values[1] == maxValue) {
        rangeSlider.noUiSlider.updateOptions({
          tooltips: [true, wNumb({ suffix: "+" })]
        });
      } else {
        rangeSlider.noUiSlider.updateOptions({
          tooltips: [true, true]
        });
      }
    });
  }
});
