document.addEventListener("turbo:load", function() {
  // sticky nav
  var nav = document.querySelector(".js-sticky-nav");
  var activeClass = "active";
  var toggleActiveNav = function() {
    if (nav) {
      if (window.pageYOffset > 0) {
        nav.classList.add(activeClass);
      } else {
        nav.classList.remove(activeClass);
      }
    }
  };

  window.onscroll = function() {
    toggleActiveNav();
  };

  toggleActiveNav();

  // mobile nav
  var toggleMobileNav = function() {
    var toggleClass = 'hidden';
    var toggleElement = document.querySelector('.js-nav-mobile-menu');
    if (toggleElement.classList.contains(toggleClass)) {
      toggleElement.classList.remove(toggleClass)
    } else {
      toggleElement.classList.add(toggleClass)
    }
  }
  var mobileNav = document.querySelector(".js-nav-mobile-button");
  if (mobileNav) {
    mobileNav.onclick = toggleMobileNav;
  }
});
