require("@rails/ujs").start();
// require("trix")
// require("@rails/actiontext")
// require("@rails/activestorage").start();
// require("../../channels");

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
const application = Application.start()
const context = require.context("./javascript/controllers", true, /\.js$/)
application.load(definitionsFromContext(context))

import "@hotwired/turbo-rails"
import "./javascript/localTime.js";
import "./javascript/multiselect.js";
import "./javascript/rangeSlider.js";
import "./javascript/nav.js";

import "./stylesheets/style.css"

// const images = require.context("./images", true);
// const imagePath = name => images(name, true);

document.addEventListener("turbo:load", function() {
  var autofocusElement = document.querySelector('[autofocus]');
  if (autofocusElement != null) {
    autofocusElement.focus();
  }
});
